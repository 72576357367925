import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/Navbar.css'; 
import logo from './images/logo.png'; 
import Web3 from 'web3'; // Import Web3 library
import { useWallet } from './WalletContext.js';
import { getOpenSeaAccountName } from './api/opensea.js'; // Import the getOpenSeaAccountName function

function Navbar() {
  const location = useLocation();
  const { walletAddress, setWalletAddress } = useWallet();
  const [hovered, setHovered] = useState(false); // Define hovered state
  const [username, setUsername] = useState(''); // State to store the username

  useEffect(() => {
    const storedWalletAddress = localStorage.getItem('walletAddress');
    if (storedWalletAddress) {
      setWalletAddress(storedWalletAddress);
      if (storedWalletAddress) {
        fetchUsername(storedWalletAddress);
      }
    }
  }, [setWalletAddress]);

  // Function to fetch username from OpenSea API
  const fetchUsername = async (address) => {
    if (address) {
      const username = await getOpenSeaAccountName(address);
      setUsername(username);
    }
  };

  // Function to connect/disconnect Metamask and get wallet address
  const handleWalletAction = async () => {
    if (walletAddress) {
      // Disconnect wallet
      setWalletAddress('');
      setUsername(''); // Reset username
      localStorage.removeItem('walletAddress'); // Remove wallet address from localStorage
    } else {
      // Connect wallet
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const web3 = new Web3(window.ethereum);
          const accounts = await web3.eth.getAccounts();
          const address = accounts[0];
          setWalletAddress(address);
          fetchUsername(address); // Fetch username after connecting wallet
          localStorage.setItem('walletAddress', address); // Store wallet address in localStorage
        } catch (error) {
          console.error('Error connecting wallet:', error);
        }
      } else {
        console.error('Metamask not installed');
      }
    }
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <Link to="/stats/" className="logo"> 
        <img src={logo} alt="Logo" />
      </Link>
      <div className="navbar-links">
        <Link to="/stats" className={`nav-link ${getLinkClass('/stats')}`}>Stats</Link>
        <Link to="/mythics" className={`nav-link ${getLinkClass('/mythics')}`}>Mythics</Link>
        <Link to="/leaderboard" className={`nav-link ${getLinkClass('/leaderboard')}`}>Leaderboard</Link>
        <Link 
          to="#" 
          className={`wallet-button nav-link ${walletAddress ? '' : 'wallet-button'}`} 
          onClick={handleWalletAction} 
          onMouseOver={() => setHovered(true)} 
          onMouseOut={() => setHovered(false)} // Set hovered state on mouse over/out
        >
          {walletAddress ? (
            hovered ? 'DISCONNECT' : (username ? username : (walletAddress.includes('.eth') ? walletAddress : `${walletAddress.slice(0, 5)}...${walletAddress.slice(-4)}`))
          ) : (
            'CONNECT'
          )}
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
