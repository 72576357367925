// Footer.js

import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './styles/Footer.css'; // Create this file for custom styles if needed

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2025 — YellowMythics</p>
        {/*
        <div className="footer-links">
          <NavLink exact="true" to="/privacy-policy" className="footer-link">Privacy Policy</NavLink>
          <NavLink exact="true" to="/disclaimer" className="footer-link">Disclaimer</NavLink>
        </div>
        */}
      </div>
    </footer>
  );
}

export default Footer;
