import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchMythicsData } from './db/dbMythics.js';
import './styles/MythicDetail.css';

const MythicDetail = () => {
    const { rank } = useParams();
    const [mythic, setMythic] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchMythic = async () => {
            const data = await fetchMythicsData();
            const selectedMythic = data.nfts.find((item) => item.rank === parseInt(rank));
            setMythic(selectedMythic);
            setIsLoading(false);
        };

        fetchMythic();
    }, [rank]);

    if (isLoading) return <div>Loading...</div>;
    if (!mythic) return <div>Mythic not found!</div>;

    return (
        <div className="mythic-detail-container">
            <div className="mythic-image">
                <img src={mythic.image_url} alt={mythic.name} />
            </div>
            <div className="mythic-info">
                <h1 className="mythic-title">{mythic.name}</h1>
                <p className="mythic-description">{mythic.description}</p>
                <p className="mythic-rank">Rank: <strong>{mythic.rank} / 60</strong></p>
                <p className="mythic-floor">Minted: <strong>{mythic.minted}</strong></p>
                <p className="mythic-floor">Owners: <strong>{mythic.owners.length}</strong></p>
                <p className="mythic-floor">Floor: <strong>{mythic.floor}</strong></p>
            </div>
        </div>
    );
};

export default MythicDetail;
