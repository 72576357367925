import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { fetchMythicsData } from './db/dbMythics.js';
import './styles/Mythics.css';
import { useWallet } from './WalletContext.js';
import getConversionRates from './api/coingecko.js';

function Mythics() {
  const { walletAddress } = useWallet();
  const [mythicData, setMythicData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState('rank');
  const [sortDirection, setSortDirection] = useState('desc');
  const [conversionRates, setConversionRates] = useState(null);

  useEffect(() => {
    const fetchAndSetData = async () => {
      setIsLoading(true);
      let data = localStorage.getItem('mythicData');
      if (!data) {
        data = await fetchMythicsData();
        localStorage.setItem('mythicData', JSON.stringify(data));
      } else {
        data = JSON.parse(data);
      }
      const sortedNfts = data?.nfts.sort((a, b) => a.rank - b.rank) || [];
      setMythicData(sortedNfts);
    };

    fetchAndSetData();
  }, []);
  
  useEffect(() => {
    const fetchAndSetData = async () => {
      setIsLoading(true);
      let data = localStorage.getItem('mythicData');
      if (!data) {
        data = await fetchMythicsData();
        localStorage.setItem('mythicData', JSON.stringify(data));
      } else {
        data = JSON.parse(data);
      }
  
      // Apply initial sort to the fetched data
      if (data?.nfts) {
        const sortedData = sortData(data.nfts, 'rank', 'desc'); // Directly use 'rank' and 'desc' here
        setMythicData(sortedData);
      }
  
      setIsLoading(false);
    };
  
    fetchAndSetData();
  }, []); // This effect depends on the component's mount, so the dependency array is empty.
  

  const fetchedRates = useRef(false);

  useEffect(() => {
    if (!fetchedRates.current) {
      const fetchRates = async () => {
        const rates = await getConversionRates();
        setConversionRates(rates);
        fetchedRates.current = true; // Mark as fetched
      };
  
      fetchRates();
    }
  }, []); // Empty dependency array ensures this effect is only run once
  

  useEffect(() => {
    if (conversionRates && mythicData.length > 0) {
      const sortedData = sortData(mythicData, sortColumn, sortDirection, conversionRates);
      setMythicData(sortedData);
    }
  }, [mythicData, sortColumn, sortDirection, conversionRates]);

  const sortData = (data, column, direction) => {
    console.log(`Sorting by ${sortColumn} in ${sortDirection} order`);
    return data.sort((a, b) => {
      if (column === 'floor' && conversionRates) {
        return direction === 'desc' ? compareFloorValues(b.floor, a.floor) : compareFloorValues(a.floor, b.floor);
      } else if (column === 'rank' || column === 'minted') {
        return direction === 'desc' ? b[column] - a[column] : a[column] - b[column];
      } else if (column === 'name') {
        return direction === 'desc' ? b[column].localeCompare(a[column]) : a[column].localeCompare(b[column]);
      } else if (column === 'owned') {
        const aOwned = getMythicsCountByRank(a.rank);
        const bOwned = getMythicsCountByRank(b.rank);
        return direction === 'desc' ? bOwned - aOwned : aOwned - bOwned;
      }
      // Assuming other columns might have different sorting logic
      return 0;
    });
  };

  const compareFloorValues = (floorA, floorB) => {
    const parseAndConvert = (floor) => {
        if (floor === "-") return 0;
        let [value, currency] = floor.replace(/,/g, '').split(" ");
        let adjustedValue = parseFloat(value);

        // Fetch the appropriate conversion rate based on the currency
        const rates = {
            eth: conversionRates.ethereum?.usd || 0,
            pol: conversionRates["matic-network"]?.usd || 0,
            usdc: conversionRates["usd-coin"]?.usd || 1 // Assuming a default of 1 if not available
        };

        const conversionFactor = rates[currency.toLowerCase()] || 0;

        // Apply the conversion factor to adjust the value to USD equivalent
        return adjustedValue * conversionFactor;
    };

    const valueInUsdA = parseAndConvert(floorA);
    const valueInUsdB = parseAndConvert(floorB);

    // Direct comparison of USD equivalent values
    return valueInUsdA - valueInUsdB;
  };

  const formatPrice = (value, currency) => {
    let adjustedValue = parseFloat(value);

    if (isNaN(adjustedValue)) {
      console.warn(`Invalid numeric value for price: ${value}`);
      return 'N/A';
    }

    if (currency.toLowerCase() === "usdc") {
      // Correct the value by the expected factor for your case
      adjustedValue *= 1;
      // Round to the nearest whole number to avoid floating-point inaccuracies
      adjustedValue = Math.round(adjustedValue);
      // Convert to string using toFixed() to handle very large numbers gracefully, without scientific notation
      return `${adjustedValue.toFixed(0)} ${currency.toUpperCase()}`;
    }

    // Handle other currencies (ETH, MATIC) with potential decimal places
    const isInteger = Number.isInteger(adjustedValue);
    return `${adjustedValue.toFixed(isInteger ? 0 : 2)} ${currency.toUpperCase()}`;
  };

  const handleSort = (newColumn) => {
    // Determine the new direction
    const newDirection = sortColumn === newColumn && sortDirection === 'desc' ? 'asc' : 'desc';
  
    // Update state
    setSortColumn(newColumn);
    setSortDirection(newDirection);
  
    // Apply sorting immediately with the new parameters
    // This ensures we're using the intended direction immediately for the sort
    const sortedData = sortData(mythicData, newColumn, newDirection, conversionRates);
    setMythicData(sortedData);
  };
  
  const getSortIndicator = (column) => {
    return sortColumn === column ? (sortDirection === 'asc' ? '▲' : '▼') : '';
  };

  const createMintedLink = (name) => {
    return `https://opensea.io/collection/yellow-ducklings?search[sortAscending]=false&search[sortBy]=CREATED_DATE&search[stringTraits][0][name]=Collection&search[stringTraits][0][values][0]=Mythics&search[stringTraits][1][name]=Mythic&search[stringTraits][1][values][0]=${encodeURIComponent(name)}`;
  };

  const createOwnedLink = (account, name) => {
    return `https://opensea.io/account?search[collections][0]=yellow-ducklings&search[stringTraits][0][name]=Collection&search[stringTraits][0][values][0]=Mythics&search[stringTraits][1][name]=Mythic&search[stringTraits][1][values][0]=${encodeURIComponent(name)}`;
  };

  const getMythicsCountByRank = (rank) => {
    return mythicData.reduce((count, mythic) => {
      if (mythic.rank === rank) {
        const ownedCount = mythic.owners.filter(owner => owner.toLowerCase() === walletAddress?.toLowerCase()).length;
        return count + ownedCount;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    // Immediately sort again if rates are updated
    if (conversionRates && Object.keys(conversionRates).length > 0) {
      const sortedData = sortData(mythicData, sortColumn, sortDirection, conversionRates);
      setMythicData(sortedData);
    }
  }, [conversionRates, mythicData, sortColumn, sortDirection]);

  return (
    <div className="mythics-container">
      {isLoading ? (
        <div className='loading'>Loading...</div>
      ) : (
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('rank')}>Rank {getSortIndicator('rank')}</th>
              <th style={{ cursor: 'default' }}></th>
              <th onClick={() => handleSort('name')}>Name {getSortIndicator('name')}</th>
              <th onClick={() => handleSort('minted')}>Minted {getSortIndicator('minted')}</th>
              {walletAddress && <th onClick={() => handleSort('owned')}>Owned {getSortIndicator('owned')}</th>}
              <th onClick={() => handleSort('floor')}>Floor {getSortIndicator('floor')}</th>
            </tr>
          </thead>
          <tbody>
            {mythicData.map((mythic, index) => (
              <tr key={index} className={walletAddress && getMythicsCountByRank(mythic.rank) > 0 ? 'highlight' : (walletAddress && getMythicsCountByRank(mythic.rank) === 0 ? 'not-yet' : '')}>
              <td className="mythics-rank">{mythic.rank}</td>
              <td className="mythics-image">
                <Link to={`/mythics/${mythic.rank}`}>
                  <img src={mythic.image_url} alt={mythic.name} height="96" />
                </Link>
              </td>
                <td className="mythics-name">{mythic.name}</td>
                <td className="mythics-minted">
                  <a href={createMintedLink(mythic.name)} target="_blank" rel="noopener noreferrer">
                    <span>{mythic.minted}</span>
                  </a>
                </td>
                {walletAddress && (
                  <td className={`mythics-owned ${getMythicsCountByRank(mythic.rank) > 0 ? 'highlight' : 'not-yet'}`}>
                    {getMythicsCountByRank(mythic.rank) > 0 ? (
                      <a href={createOwnedLink(walletAddress, mythic.name)} target="_blank" rel="noopener noreferrer">
                        {getMythicsCountByRank(mythic.rank)}
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                )}
                  <td className="mythics-floor">
                    {mythic.floor && mythic.floor !== "-" ? (() => {
                      const parts = mythic.floor.split(" ");
                      // Ensure the floor value has both parts: value and currency
                      if (parts.length === 2) {
                        const [value, currency] = parts;
                        return formatPrice(value, currency);
                      } else {
                        // Log unexpected format for debugging
                        console.warn(`Unexpected floor format for mythic: ${mythic.name} with floor: ${mythic.floor}`);
                        return 'N/A'; // Return 'N/A' or some placeholder indicating unavailable floor price
                      }
                    })() : '-'}
                  </td>

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Mythics;