import { db } from './firebase.js';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';

export async function fetchMythicsData() {
    console.log('Fetching Mythics data from Firestore...');
    const nftsCollection = collection(db, 'nfts');
    const floorPriceCollection = collection(db, 'floor');
    let floorPrices = {};

    try {
        // Fetch the latest floor price document by ordering the collection by name in descending order
        const q = query(floorPriceCollection, orderBy('__name__', 'desc'), limit(1));
        const floorPriceSnapshot = await getDocs(q);

        // Pick the first (and only) document from the sorted list
        const latestFloorPriceDoc = floorPriceSnapshot.docs[0];
        floorPrices = latestFloorPriceDoc.data();
        console.log('Doc data:', floorPrices);

        const nftsSnapshot = await getDocs(nftsCollection);
        const nftData = processMythicsData(nftsSnapshot.docs, floorPrices);
        console.log('Fetched Mythics data:', nftData);
        return { nfts: nftData };
    } catch (error) {
        console.error('Error fetching Mythics data from Firestore:', error);
        return { nfts: [] };
    }
}

function processMythicsData(docs, floorPrices) {
    const nftData = {};
    docs.forEach(doc => {
        const nft = doc.data();
        if (nft.rank && nft.name) {
            const floorPrice = floorPrices[nft.rank] || "-";
            nftData[nft.rank] = {
                rank: nft.rank,
                name: nft.name,
                description: nft.description,
                image_url: nft.image_url,
                floor: floorPrice, // Add floorPrice to each NFT
                minted: nftData[nft.rank] ? nftData[nft.rank].minted + 1 : 1,
                owners: nftData[nft.rank] ? [...nftData[nft.rank].owners, nft.owner] : [nft.owner]
            };
        }
    });
    return Object.values(nftData);
}
